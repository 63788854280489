<template>
  <section>
    <LoadingSpinner v-if="loading" />
    <b-container class="py-md-4">
      <div class="p-3 mt-2">
        <b-row class="justify-content-center">
          <b-col
            xl="12"
            lg="12"
          >
            <b-row
              class="justify-content-right"
              style="justify-content:right !important"
            >
              <b-col 
                md="7"
                class="text-right"
              >
                <img
                  v-if="isVideo === false"
                  :src="image"
                  class="w-100 advertisement-image"
                >
                <video
                  v-else
                  id="video-file"
                  style="width: 100%"
                  class="video-file"
                  autoplay
                  muted
                >
                  <source
                    :src="image"
                    type="video/mp4"
                  >
                </video>
                <div class="text-left mt-3">
                  <p class="font-weight-bold font-open-sans">
                    {{ title }}
                  </p>
                </div>
                <b-row>
                  <b-col
                    md="6"
                    class="text-left font-weight-light"
                  >
                    <span>{{ date }} ago</span>
                  </b-col>
                  <!-- <b-col
                    md="6"
                    :class="['text-right font-weight-light']"
                  >
                    <a
                      href="https://www.twitter.com"
                      target="_blank"
                    ><img
                      src="../../assets/images/twitter1.png"
                      alt="twitter"
                      class="mr-3"
                      style="width:25px"
                    ></a>
                    <a
                      href="https://www.facebook.com"
                      target="_blank"
                    ><img
                      src="../../assets/images/facebook1.png"
                      alt="facebook"
                      style="width:25px"
                    ></a>
                  </b-col> -->
                </b-row>
                <p class="font-weight-light text-left mt-3">
                  {{ message }}
                </p>
              </b-col>
              <b-col md="3">
                <div>
                  <advertisement
                    v-for="(advertisement, aIndex) in advertisements"
                    :key="`advertisement${aIndex}`"
                    :advImage="advertisement.advImage"
                    :advText="advertisement.advText"
                    :advId="advertisement.advId"
                  />
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-row />
        </b-row>
      </div>
    </b-container>
  </section>
</template>

<script>
import style from "../../styles/celebsearch.module.scss";
import Advertisement from '../Advertisements/Advertisement.vue';
import LoadingSpinner from '../Common/LoadingSpinner.vue';
import { advertisementsList, getAdsDetail, advertisementStats } from '../../api/feed';
import { getFromNowTime } from "../../js/common-function";
export default {
    name:'AdDetails',
    components:{ Advertisement, LoadingSpinner },
    data(){
        return {
            style,
            advertisements:[],
            image: "",
            title:"",
            message:"",
            date: "",
            isVideo: false,
            loading:false,
            fromNowTime: getFromNowTime,
        };
    },
    async created(){
        this.loading = true;
        if (document.getElementById('newsFeed') !== null){
            document.getElementById('newsFeed').firstChild.classList.remove('router-link-active');
        }
        let advertisement = await advertisementsList(window.atob(this.$route.params.id));
        if (advertisement.length > 0){
            this.loading = false;
            this.advertisements = advertisement;
        }
        if (this.$route.params.id){
            this.loading = true;
            let adDetails = await getAdsDetail(window.atob(this.$route.params.id));
            if (Object.keys(adDetails).length > 0){
                this.loading = false;
                this.image = adDetails.media_file_url;
                adDetails.media_file_url.includes('.mp4') ? this.isVideo = true : this.isVideo = false;
                this.title = adDetails.title;
                this.message = adDetails.message;
                this.date = this.fromNowTime(adDetails.created_at);
            }

            // Call advertisement stats API
            let statsType = 2;
            advertisementStats(window.atob(this.$route.params.id),statsType);
        }
    }
};
</script>